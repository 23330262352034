import React from 'react';
import _ from 'lodash';
import {safePrefix} from "../utils";

export default class SectionImage extends React.Component {
    render() {
        let section = _.get(this.props, 'section');
        return (
            <section id={_.get(this.props, 'section.section_id')} className={'block features-block outer'}>
                <div className="inner-medium">
                    {_.get(this.props, 'section.image') &&
                    <div className="post-thumbnail">
                        <img src={safePrefix(_.get(this.props, 'section.image'))}
                             alt={_.get(this.props, 'section.title')}/>
                    </div>
                    }
                </div>
            </section>
        );
    }
}
