import React from 'react';

export default class Calendly extends React.Component {

    CALENDLY_URL = 'https://calendly.com/pd-tech-sales/inquiry';
    DEFERRED_TIMEOUT = 1000;

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
        setTimeout(() => {
            window.Calendly.initBadgeWidget({
                url: this.CALENDLY_URL,
                text: 'Schedule time with us',
                color: '#00a2ff',
                textColor: '#ffffff',
                branding: false
            });
        }, this.DEFERRED_TIMEOUT);
    }

    componentWillUnmount() {
        if (this.props.shouldUnmount) {
            window.Calendly.destroyBadgeWidget();
        }
    }

    render() {
        if (this.props.renderSection) {
            return <section>
                <div
                    className="calendly-inline-widget"
                    data-url={this.CALENDLY_URL}
                    style={{minWidth: '320px', height: '620px'}}/>
            </section>
        }
        return null
    }
}