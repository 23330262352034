import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {safePrefix} from '../utils';
import Header from './Header';
import Footer from './Footer';
import Calendly from "../integrations/Calendly";
import Google from "../integrations/Google";

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0"/>
                    <meta name="google" content="notranslate"/>
                    <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i"
                          rel="stylesheet"/>
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')}/>
                    <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
                    {/* FAVICON LINKS */}
                    <link rel="icon"
                          href="https://pdtechsolutions-website-content.s3.amazonaws.com/apple-touch-icon.png"/>
                    <link rel="icon" href="https://pdtechsolutions-website-content.s3.amazonaws.com/favicon.ico"/>
                    <link rel="icon" href="https://pdtechsolutions-website-content.s3.amazonaws.com/favicon-32x32.png"/>
                    <link rel="icon" href="https://pdtechsolutions-website-content.s3.amazonaws.com/favicon-16x16.png"/>
                    <link rel="icon" href="https://pdtechsolutions-website-content.s3.amazonaws.com/site.webmanifest"/>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-155173170-1"></script>
                </Helmet>
                <div id="page" className={'site palette-' + _.get(this.props, 'pageContext.site.siteMetadata.palette')}>
                  <Header {...this.props} />
                  <main id="content" className="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                  {/* INTEGRATIONS BELOW */}
                  <Calendly renderSection={false} shouldUnmount={false} />
                  <Google />
                </div>
            </React.Fragment>
        );
    }
}
