import React from 'react';

export default class Google extends React.Component {

    componentDidMount() {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'UA-155173170-1');
    }

    render() {
        return null;
    }
}